<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">资讯分类</h3>
    </div>
    <div class="box">
      <div class="titles">
        <el-button size="small" type="primary" @click="dialogNewsCate = true"
          >添加分类</el-button
        >
        <div class="ipts">
          <p>
            <span>类型名称:</span
            ><input
              v-model="listForm.name"
              placeholder="请输入名称"
              type="text"
            />
          </p>
        </div>
      </div>
      <div class="tables">
		<el-table
		  row-key="id"
		  :tree-props="tableprops"
		  border
		  :data="list"
		  :row-style="{ height: '30px', padding: '0' }"
		  default-expand-all
		>
          <el-table-column prop="id" label="分类id"> </el-table-column>
          <el-table-column prop="name" label="分类名称"> </el-table-column>
          <el-table-column prop="purview" label="权限"> 
			<div slot-scope="scope">
			  <span style="color: #0275D8;" v-if="scope.row.purview==2">用户可见</span>
			  <span style="color: #DA0618;" v-if="scope.row.purview==1">门店可见</span>
			</div>
		  </el-table-column>
		  <el-table-column prop="isedit" label="编辑权限">
				<div slot-scope="scope">
				  <span style="color: #0275D8;" v-if="scope.row.isedit==2">不允许</span>
				  <span style="color: #DA0618;" v-if="scope.row.isedit==1">允许</span>
				</div>
		  </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <el-button
                size="mini"
                round
                type="primary"
                @click="bianji(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                round
                type="danger"
                @click="deleteCate(scope.row.id)"
                >删除</el-button
              >
            </div>
          </el-table-column>
		</el-table>  
        <el-pagination
          style="margin-top: 20px"
          @current-change="cateListCrtChg"
          :current-page="listForm.page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加编辑类型 -->
    <el-dialog title="资讯分类" width="30%" :visible.sync="dialogNewsCate">
      <el-form :rules="rules"  label-width="140px" :model="newsCateForm" ref="addnewscate">
      <el-form-item label="父级:" >
        <div class="block">
          <el-cascader
            :props="optionProps"
            :options="options"
            @change="setclass"
            ref="cascader"
          ></el-cascader>
        </div>
      </el-form-item>
        <el-form-item label="名称:" prop="name">
          <el-input
            v-model="newsCateForm.name"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="weigh" label="排序:">
          <el-input
            v-model="newsCateForm.sort"
            placeholder="请输入分类排序"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="purview">
            <el-radio v-model="newsCateForm.purview" :label="1">门店可见</el-radio>
            <el-radio v-model="newsCateForm.purview" :label="2">用户可见</el-radio>
        </el-form-item>
		<el-form-item label="是否允许用户编辑" prop="purview">
		    <!-- isedit -->
			<el-switch @change="isedit" v-model="newsCateForm.isedit"></el-switch>
		</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogNewsCate = false">取 消</el-button>
        <el-button type="primary" @click="addNewsCategory">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  findAllNewsCategory,
  addNewsCategory,
  findNewsCateDetail,
  delNewsCate
} from "@/api/api.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      tableprops: {
        children: "children",
      },	
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
	    options:[],
      listForm: {
        name: "",
        page: 1,
        limit: 10,
      },
      total: 1,
      list: [],
      //  添加编辑分类
      dialogNewsCate: false,
      newsCateForm: {
        name: "",
        parent_id:'',
        sort:'',
        purview:'',
		isedit:false,
      },
	    id:'',
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }]
      },
    };
  },
  watch: {
    dialogNewsCate: function (newData, oldData) {
      if (!newData) {
        delete this.newsCateForm.id;
        this.newsCateForm.name = "";
      }
    },
  },
  methods: {
    setclass(e){
      console.log(e)
      this.newsCateForm.parent_id=e[0]
    },

    // 删除新闻分类
    deleteCate(id) {
      this.$confirm("真的要删除该分类吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delNewsCate({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.list.forEach((item) => {
            if (item.id == id) {
              let index = this.list.indexOf(item);
              this.list.splice(index, 1);
            }
          });
          this.getlist();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    isedit(){
      // if(this.newsCateForm.isedit==false){
      // 	this.newsCateForm.isedit=1
      // }else{
      // 	this.newsCateForm.isedit=2
      // }
    },

    async bianji(item) {
        const { data } = await findNewsCateDetail({ id: item.id });
        // console.log(data.data)
        this.newsCateForm.id = data.data.info.id;
        this.newsCateForm.name = data.data.info.name;
        this.newsCateForm.sort = data.data.info.sort;
        this.newsCateForm.purview = data.data.info.purview;
        if(data.data.info.isedit==1){
          this.newsCateForm.isedit=true
        }else{
          this.newsCateForm.isedit=false
        }
        console.log(this.newsCateForm.isedit)
        this.dialogNewsCate = true;
    },
    
    // 添加新闻分类
    addNewsCategory() {
		if(this.newsCateForm.isedit==false){
			this.newsCateForm.isedit=2
		}else{
			this.newsCateForm.isedit=1
		}
      this.$refs["addnewscate"].validate(async (valid) => {
        if (valid) {
          const { data } = await addNewsCategory(this.newsCateForm);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message.success(data.data);
          this.dialogNewsCate = false;
          this.getlist();
        } else {
          this.$message.warning("请检查填写内容!");
          return false;
        }
      });
    },

    // 获取新闻分类列表
    async getlist() {
        const { data } = await findAllNewsCategory(this.listForm);
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.classify_list;
		    this.options=data.data.classify_list;
    },

    cateListCrtChg(e) {
      this.listForm.page = e;
      this.getlist();
    },

  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.titles {
  background-color: rgb(241, 243, 244);
  padding: 10px;
  p {
    font-size: 14px;
    margin: 10px 0;
    span {
      margin-right: 5px;
    }
    input {
      height: 30px;
    }
  }
}
.tables {
  margin: 20px;
}
.el-dialog {
  .el-input {
    width: 50%;
  }
}
.xiugai {
    color: #999;
    border-bottom:  1px solid #999;
    margin-left: 12px;
}
</style>